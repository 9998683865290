import { USER_LOGIN, SET_USER, VERIFY_USER } from "../ActionTypes";

const initialState = {
  auth: false,
  user: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    case VERIFY_USER:
      return state;
    case USER_LOGIN:
      return state;
    default:
      return state;
  }
};

export default authReducer;
