import React, { useState } from "react";
import {
  // Link,
  useHistory
} from "react-router-dom";
import "../index.css";
const MobileHeader = ({ auth, setAuth, logOut, user }) => {
  const [show, setShow] = useState(false);

  const history = useHistory();
  return (
    <div className='mobile__header'>
      <img
        src='/images/logo.png'
        loading='lazy'
        onClick={() => history.push("/")}
        width={52}
        alt={""}
        className='mobile__logo'
      />
      <div
        className={`menu-block mobile__navigation-btn${
          auth ? "-inactive" : ""
        } w-inline-block`}>
        <div className='w-embed'>
          <button
            type='button'
            aria-label='Open and close navigation menu'
            aria-haspopup='true'
            data-test='open'
            className='mobile__navigation-btn'>
            <span className='mobile__menu-line is--top' />
            <span className='mobile__menu-line is--bottom' />
          </button>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n          .mobile__navigation-btn {\n            width: 34px;\n            height: 24px;\n            position: relative;\n            cursor: pointer;\n            border: none;\n            margin: 0;\n            padding: 0;\n            text-align: inherit;\n            background: transparent;\n            color: inherit;\n            font: inherit;\n            line-height: normal;\n            transform: scale(0.9);\n          }\n\n          .mobile__menu-line {\n            backface-visibility: hidden;\n            width: 100%;\n            height: 4px;\n            position: absolute;\n            left: 0px;\n            top: 10px;\n            transform-origin: center center;\n            transition: transform 0.3s ease 0s, background 0.3s ease 0s;\n            background-image: url(https://uploads-ssl.webflow.com/609ba4da89f30169bedb2624/60c542a39af862c712eea909_mobile__nav-line.svg);\n          }\n\n          .mobile__menu-line.is--top {\n            transform: translateY(-4.5px);\n          }\n\n          .mobile__menu-line.is--bottom {\n            transform: translateY(4.5px);\n          }\n\n          .mobile__navigation-btn.is--open .mobile__menu-line.is--top {\n            transform: rotate(45deg);\n          }\n\n          .mobile__navigation-btn.is--open .mobile__menu-line.is--bottom {\n            transform: rotate(-45deg);\n          }\n        "
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
