// eslint-disable-next-line
import { all } from "redux-saga/effects";

// call all your watchers here
import { watchUserLogin } from "./authSaga";

// eslint-disable-next-line require-jsdoc
export default function* rootSaga() {
  yield all([watchUserLogin()]);
}
