import React from "react";
const About = React.lazy(() => import("./components/About"));
const AgencyAndConcierge = React.lazy(() =>
  import("./components/AgencyAndConcierge")
);
const AgencyList = React.lazy(() => import("./components/AgencyList"));
const BlogPost = React.lazy(() => import("./components/BlogPost"));
const BlogList = React.lazy(() => import("./components/BlogList"));
const AgencyPage = React.lazy(() => import("./components/AgencyPage"));
const EditProfile = React.lazy(() => import("./components/EditProfile"));
const Home = React.lazy(() => import("./components/Home"));
const Login = React.lazy(() => import("./components/Login"));
const ForgotPassword = React.lazy(() => import("./components/ForgotPassword"));
const Registration = React.lazy(() => import("./components/Registration"));
const ShortList = React.lazy(() => import("./components/ShortList"));
const EmailVerified = React.lazy(() => import("./components/EmailVerified"));
const ResetPassword = React.lazy(() => import("./components/ResetPassword"));
const UserReviews = React.lazy(() => import("./components/UserReviews"));

const routes = [
  {
    path: "/about",
    exact: true,
    component: About,
    name: "About"
  },
  {
    path: "/join-wekaala",
    exact: true,
    component: AgencyAndConcierge,
    name: "AgencyAndConcierge"
  },
  {
    path: "/concierge",
    exact: true,
    component: AgencyAndConcierge,
    name: "Concierge"
  },
  {
    path: "/find-agency",
    exact: true,
    component: AgencyList,
    name: "AgencyList"
  },
  {
    path: "/blog/:blogId",
    exact: true,
    component: BlogPost,
    name: "BlogPost"
  },
  {
    path: "/blogs",
    exact: true,
    component: BlogList,
    name: "BlogList"
  },
  {
    path: "/blogs/category/:categoryName",
    exact: true,
    component: BlogList,
    name: "BlogList"
  },
  {
    path: "/agency/:agencyId",
    exact: true,
    component: AgencyPage,
    name: "AgencyPage"
  },
  {
    path: "/login",
    exact: true,
    component: Login,
    name: "Login"
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword,
    name: "ForgotPassword"
  },
  {
    path: "/change-password",
    exact: true,
    component: ForgotPassword,
    name: "ChangePassword"
  },
  {
    path: "/edit-profile",
    exact: true,
    component: EditProfile,
    name: "EditProfile"
  },
  {
    path: "/register",
    exact: true,
    component: Registration,
    name: "Registration"
  },
  {
    path: "/email-verify",
    exact: true,
    component: EmailVerified,
    name: "EmailVerified"
  },
  {
    path: "/forgot-password/:resetPasswordHash",
    exact: true,
    component: ResetPassword,
    name: "ResetPassword"
  },
  {
    path: "/shortlist",
    exact: true,
    component: ShortList,
    name: "ShortList"
  },
  {
    path: "/reviews",
    exact: true,
    component: UserReviews,
    name: "Reviews"
  },
  {
    path: "/",
    exact: true,
    component: Home,
    name: "Home"
  }
];

export default routes;
