import React from "react";
// import { Link } from "react-router-dom";

const MobileMenu = ({ closeMenu, auth, setAuth, logOut, user }) => (
  <div className='mobile__menu' id='mobile-nav' style={{ overflow: "auto" }}>
    <a
      style={{ textDecoration: "none", color: "inherit" }}
      href='/find-agency'
      onClick={closeMenu}
      className='mobile__menu--block w-inline-block'>
      <div className='mobile__menu-block-title'>Find Agency</div>
    </a>
    <a
      style={{ textDecoration: "none", color: "inherit" }}
      href='/about'
      onClick={closeMenu}
      className='mobile__menu--block w-inline-block'>
      <div className='mobile__menu-block-title'>About</div>
    </a>
    <a
      style={{ textDecoration: "none", color: "inherit" }}
      href='/blogs/category/all'
      onClick={closeMenu}
      className='mobile__menu--block w-inline-block'>
      <div className='mobile__menu-block-title'>Insights</div>
    </a>
    <a
      style={{ textDecoration: "none", color: "inherit" }}
      href='/concierge'
      onClick={closeMenu}
      className='mobile__menu--block w-inline-block'>
      <div className='mobile__menu-block-title'>Concierge </div>
    </a>
    <a
      style={{ textDecoration: "none", color: "inherit" }}
      href='/join-wekaala'
      onClick={closeMenu}
      className='mobile__menu--block w-inline-block'>
      <div className='mobile__menu-block-title'>Are you an agency?</div>
    </a>
    {auth ? (
      <>
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href='/shortlist'
          onClick={closeMenu}
          className='mobile__menu--block w-inline-block'>
          <div className='mobile__menu-block-title'>Shortlist Agency </div>
        </a>
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href='/reviews'
          onClick={closeMenu}
          className='mobile__menu--block w-inline-block'>
          <div className='mobile__menu-block-title'>Reviews </div>
        </a>
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href='/edit-profile'
          onClick={closeMenu}
          className='mobile__menu--block w-inline-block'>
          <div className='mobile__menu-block-title'>Profile </div>
        </a>
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href='/change-password'
          onClick={closeMenu}
          className='mobile__menu--block w-inline-block'>
          <div className='mobile__menu-block-title'>Change Password </div>
        </a>
        <hr />
        <div
          style={{ textDecoration: "none", color: "inherit" }}
          onClick={() => {
            logOut();
            closeMenu();
          }}
          className='mobile__menu--block w-inline-block'>
          <div className='mobile__menu-block-title'>Sign Out </div>
        </div>
      </>
    ) : (
      <a
        style={{ textDecoration: "none", color: "inherit" }}
        href='/login'
        onClick={closeMenu}
        className='mobile__menu--block w-inline-block'>
        <div className='mobile__menu-block-title'>Login/Signup </div>
      </a>
    )}
  </div>
);

export default MobileMenu;
