import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

const Header1 = ({ auth, setAuth, logOut, user }) => {
  const [show, setShow] = useState(false);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };
    // Get the header
    var header = document.getElementById("navigation-bar");
    // Get the offset position of the navbar
    var sticky = header.offsetTop;
    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        setSticky(true);
        let menulinks = header.getElementsByClassName("navgiation__menulink");
        for (let m of menulinks) {
          m.style.color = "black";
        }
        let agency = document.querySelector(".cta__primary.is--white");
        if (agency) {
          agency.classList.remove("is--white");
          agency.classList.add("is--primary");
          agency.classList.add("is--navigation");
          agency.classList.add("is--header-button");
        }
        let login = document.querySelector("#login-button");
        if (login) {
          login.classList.remove("cta__white");
          login.classList.add("cta__primary");
          login.classList.add("is--navigation");
          login.classList.add("is--header-button");
        }
      } else {
        setSticky(false);
        header.classList.remove("sticky");
        let menulinks = header.getElementsByClassName("navgiation__menulink");
        for (let m of menulinks) {
          m.style.color = "white";
        }
        let agency = document.querySelector(".is--primary");
        if (agency) {
          agency.classList.remove("is--primary");
          agency.classList.remove("is--navigation");
          agency.classList.add("is--white");
          agency.classList.remove("is--header-button");
        }
        let login = document.querySelector("#login-button");
        if (login) {
          login.classList.remove("cta__primary");
          login.classList.remove("is--navigation");
          login.classList.add("cta__white");
          login.classList.remove("is--header-button");
        }
      }
    }
  }, []);

  return (
    <div id='navigation-bar' className='navigaiton__header isnav'>
      <div className='week__container is--nav'>
        <div className='navigation__inner'>
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href='/'
            className='nav__left'>
            <img
              src='/images/logo.png'
              loading='lazy'
              alt={""}
              className='navigation__logo'
            />
          </a>
          <div className='nav__left' />
          <div className='navgiation__cta-wrap'>
            {!auth ? (
              <>
                <a
                  style={{ textDecoration: "none" }}
                  href='/join-wekaala'
                  className='cta__primary is--outline is--white w-button'>
                  Are you an agency?
                </a>
                <a
                  id='login-button'
                  style={{
                    textDecoration: "none",
                    color: sticky ? "white" : "inherit"
                  }}
                  href='/login'
                  className='cta__white w-button'>
                  Login/Signup
                </a>
              </>
            ) : (
              <div className='navigation__avatar'>
                <div
                  onClick={() => setShow(!show)}
                  className='navigation__avtar--inner w-inline-block'>
                  <img
                    src={
                      user.avatar ||
                      "https://assets.website-files.com/60f014951cfede4678ed524f/60f343e63a403b5172cf4125_howitworks_compare.png"
                    }
                    loading='lazy'
                    alt=''
                    className='navavatar__icon'
                  />
                </div>
                <div
                  className='navigation__dd'
                  style={{ display: show ? "flex" : "none" }}>
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href='/shortlist'
                    className='shortlist__dd--link'>
                    Shortlist Agency
                  </a>
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href='/reviews'
                    className='shortlist__dd--link'>
                    Reviews
                  </a>
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href='/edit-profile'
                    className='shortlist__dd--link'>
                    Profile
                  </a>
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href='/change-password'
                    className='shortlist__dd--link'>
                    Change Password
                  </a>
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href='/join-wekaala'
                    className='shortlist__dd--link'>
                    Add Agency
                  </a>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={logOut}
                    className='shortlist__dd--link is--signout'>
                    Sign Out
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='navigation__menuwrap'>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
                color: "white"
              }}
              href='/find-agency'
              className='navgiation__menulink'>
              Find Agency
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
                color: "white"
              }}
              href='/blogs/category/all'
              className='navgiation__menulink'>
              Insights
            </a>
            <a
              style={{
                textDecoration: "none",
                color: "inherit",
                color: "white"
              }}
              href='/concierge'
              className='navgiation__menulink'>
              Concierge{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header1;
