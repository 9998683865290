import { createStore, applyMiddleware } from "redux";
// import throttle and persist for listener
import { throttle } from "lodash";
import { persist } from "./persist";

import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "../Saga";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

// saga middlewares initialize here
sagaMiddleware.run(rootSaga);

// create a state listener to restore state from localStorage
const listener = throttle(() => {
  const state = store.getState();
  // persist(state, "ProjectReducer.currentProjectCard");
}, 5000);

store.subscribe(listener);

export default store;
