import React from "react";
// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='week__container is--footer'>
        <div className='w-layout-grid footer__grid'>
          <div className='footer__content'>
            <div className='footer__content--title'>
              List your agency on Wekaala
            </div>
            <div className='footer__content-description is--white'>
              Don't miss out on the chance to be on the only dedicated online
              platform for marketing agencies across MENA and South Asia.
            </div>
            <a
              style={{ textDecoration: "none", color: "white" }}
              href='/join-wekaala'
              className='cta__primary is--footer w-button'>
              Join Wekaala
            </a>
          </div>
          <div className='divempty' />
          <div className='footer__menubox'>
            <div className='footer__menulinks-container'>
              <ul className='footer__menulist'>
                <li className='footer__menublock'>
                  <a
                    style={{ textDecoration: "none" }}
                    href='/find-agency'
                    className='footer__menulink'>
                    Find Agencies
                  </a>
                </li>
                <li className='footer__menublock'>
                  <a
                    href='https://www.instagram.com/wekaala/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='footer__menulink'>
                    Instagram
                  </a>
                </li>
                <li className='footer__menublock'>
                  <a
                    style={{ textDecoration: "none" }}
                    href='/blogs/category/all'
                    className='footer__menulink'>
                    Insights
                  </a>
                </li>
                <li className='footer__menublock'>
                  <a
                    href='https://www.linkedin.com/company/wekaala/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='footer__menulink'>
                    LinkedIn
                  </a>
                </li>
                <li className='footer__menublock'>
                  <a
                    style={{ textDecoration: "none" }}
                    href='/concierge'
                    className='footer__menulink'>
                    Concierge
                  </a>
                </li>

                <li className='footer__menublock'>
                  <a href='/about#contact' className='footer__menulink'>
                    Contact Us
                  </a>
                </li>
                <li className='footer__menublock'>
                  <a
                    style={{ textDecoration: "none" }}
                    href='/about'
                    aria-current='page'
                    className='footer__menulink w--current'>
                    About Wekaala
                  </a>
                </li>
              </ul>
            </div>
            <div className='copyright__text'>© 2022 Wekaala</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
