import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./app/State";
import "./assets/css/normalize.css";
import "./assets/css/webflow.css";
import "./assets/css/weekala.webflow.css";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
// import "./assets/css/material-ui.css";
import "react-notifications/lib/notifications.css";
import Helmet from "react-helmet";

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <meta name='description' content='' />
      <meta property='og:url' content='https://wekaala.com' />
      <meta property='og:image' content='https://wekaala.com/images/logo.png' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content='Wekaala' />
      <meta property='og:site_name' content='Wekaala' />
      <meta property='twitter:card' content='summary' />
      <meta property='twitter:url' content='https://wekaala.com' />
      <meta property='twitter:title' content='Wekaala' />
      <meta
        property='twitter:image'
        content='https://wekaala.com/images/logo.png'
      />
      {/* <!-- <meta property="twitter:site" content="@centreforsight1"> --> */}
      {/* <!-- <meta property="twitter:creator" content="@centreforsight1"> --> */}
    </Helmet>
    <App />
    <NotificationContainer />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
