import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../../../routes";

const MainContainer = () => {
  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          component={(props) => (
            <Suspense fallback={<div>Loading</div>}>
              <route.component {...props} />
            </Suspense>
          )}
        />
      ))}
    </Switch>
  );
};

export default MainContainer;
