import { USER_LOGIN, USER_LOGOUT, VERIFY_USER, SET_USER } from "../ActionTypes";

export const userLogin = (payload) => {
  return { type: USER_LOGIN, payload };
};

export const setUser = (payload) => {
  return { type: SET_USER, payload };
};

export const userLogout = (payload) => {
  return { type: USER_LOGOUT, payload };
};

export const verifyUser = () => {
  return { type: VERIFY_USER };
};
