import React, { useEffect, useState } from "react";
import TransparentHeader from "./Sections//HomeHeader";
import WhiteHeader from "./Sections//DesktopHeader";
import Footer from "./Sections/Footer";
import MobileHeader from "./Sections/MobileHeader";
import MobileMenu from "./Sections/MobileMenu";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainContainer from "./Sections/MainContainer";
import "./index.css";
import api from "../../app/resources";
import { NotificationManager } from "react-notifications";
const Layout = () => {
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    document
      .querySelector(".mobile__navigation-btn")
      .addEventListener("click", function () {
        document.querySelector(".mobile__navigation-btn") &&
          document
            .querySelector(".mobile__navigation-btn")
            .classList.toggle("is--open");
        document.querySelector(".mobile__menu") &&
          document
            .querySelector(".mobile__menu")
            .classList.toggle("mobile__menu-active");
      });

    api
      .post("/session")
      .then((res) => {
        setAuth(res.data.auth);
        setUser(res.data.user || {});
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error(err.message);
      });
  }, []);

  const logOut = () => {
    api
      .get("/logout")
      .then(() => {
        setAuth(false);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error(err.message);
      });
  };

  const closeMenu = () => {
    document.querySelector(".mobile__navigation-btn") &&
      document
        .querySelector(".mobile__navigation-btn")
        .classList.toggle("is--open");
    document.querySelector(".mobile__menu") &&
      document
        .querySelector(".mobile__menu")
        .classList.toggle("mobile__menu-active");
  };

  return (
    <Router>
      <Switch>
        <Route
          path='/'
          exact
          render={() => (
            <TransparentHeader
              user={user}
              logOut={logOut}
              auth={auth}
              setAuth={setAuth}
            />
          )}
        />

        <Route
          path='/find-agency'
          exact
          render={() => (
            <TransparentHeader
              user={user}
              logOut={logOut}
              auth={auth}
              setAuth={setAuth}
            />
          )}
        />
        <Route
          path='/join-wekaala'
          exact
          render={() => (
            <TransparentHeader
              user={user}
              logOut={logOut}
              auth={auth}
              setAuth={setAuth}
            />
          )}
        />
        <Route
          path='/concierge'
          exact
          render={() => (
            <TransparentHeader
              user={user}
              logOut={logOut}
              auth={auth}
              setAuth={setAuth}
            />
          )}
        />
        <Route
          path='*'
          render={() => (
            <WhiteHeader
              user={user}
              logOut={logOut}
              setAuth={setAuth}
              auth={auth}
            />
          )}
        />
      </Switch>
      <MobileHeader user={user} logOut={logOut} auth={auth} setAuth={setAuth} />
      <MainContainer />
      <Footer />
      <MobileMenu
        user={user}
        logOut={logOut}
        closeMenu={closeMenu}
        auth={auth}
        setAuth={setAuth}
      />
    </Router>
  );
};

export default Layout;
