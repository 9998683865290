/* eslint-disable require-jsdoc */
import { VERIFY_USER } from "../State/ActionTypes";
import { takeLatest, put, call } from "redux-saga/effects";
import { setUser } from "../../app/State/actions/AuthActions";
import api from "../resources";

// define all your api calls here
function* verifyUserAsync(action) {
  const res = yield call(() => {
    return api.post("/session");
  });
  yield put(setUser(res.data));
}

export function* watchUserLogin() {
  yield takeLatest(VERIFY_USER, verifyUserAsync);
}
